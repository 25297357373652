import { ElementBuilder } from "../includes/_DOMutils";

class Modal extends HTMLElement {
	connectedCallback() {
		this.querySelectorAll("[data-close]").forEach((el) =>
			el.addEventListener("click", (e) => {
				e.preventDefault();
				this.setAttribute("open", "false");
			})
		);
	}

	get open() {
		return this.getAttribute("open") === "true";
	}

	set open(value) {
		if (value) {
			this.setAttribute("open", value);
		} else {
			this.removeAttribute("open");
		}
	}

	static get observedAttributes() {
		return ["open"];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === "open") {
			if (newValue === "true") {
				this.classList.remove("opacity-0", "pointer-events-none");
				this.classList.add("opacity-100", "transition-opacity");
			} else {
				this.classList.add("opacity-0", "pointer-events-none");
				this.classList.remove("opacity-100");
			}
		}
	}
}

customElements.define("app-modal", Modal);

export default function setupOpeners() {
	document.querySelectorAll("[data-modal-open]").forEach((el) => {
		el.addEventListener("click", (e) => {
			if (!(e.target instanceof HTMLInputElement)) {
				e.preventDefault();
			}
			const modal = document.querySelector(`#${el.dataset.modalOpen}`);
			modal.open = !modal.open;
		});
	});
}

class ModalOpener extends HTMLButtonElement {
	// better version of the above, reattaches handlers on ajax
	connectedCallback() {
		const target = `#${this.getAttribute('target')}`;
		const modal = document.querySelector(target);
		this.addEventListener("click", e => {
			e.preventDefault();
			modal.open = !modal.open;
		})
	}
}
window.customElements.define('modal-opener', ModalOpener, {extends: 'button'})
