import { AjaxForm } from "@neonjungle/birdseed/forms/ajax";

class OnChangeForm extends AjaxForm {
	getFetchOptions(formMethod) {
		let fetchOptions = super.getFetchOptions(formMethod);
		fetchOptions["headers"]["X-Change-Trigger"] = this.eventTrigger.name;
		return fetchOptions;
	}
	setupListeners() {
		this.form.querySelectorAll("[data-ajax-onchange]").forEach((input) => {
			if (input.getAttribute("listening") !== "true") {
				input.addEventListener("change", (e) => {
					this.eventTrigger = e.target;
					if (e.target.checkValidity()) {
						this.fetchAndReplace();
					}
				});
				input.setAttribute("listening", "true");
			}
		});
		this.form.querySelectorAll("[data-ajax-onkeyup]").forEach((input) => {
			if (input.getAttribute("listening") !== "true") {
				input.addEventListener("keyup", (e) => {
					this.eventTrigger = e.target;
					this.fetchAndReplace();
				});
				input.setAttribute("listening", "true");
			}
		});
		const submitButton = this.form.querySelector('button[type="submit"]');
		if (submitButton) {
			this.form.addEventListener("submit", (e) => {
				// This is to kill the double submit
				submitButton.disabled = true;
				submitButton.classList.add("btn-disabled");
			});
		}
	}

	afterFetch() {
		this.setupListeners();
		super.afterFetch();
	}
}

export default function () {
	let form = document.querySelector("[data-ajax-change-form]");
	if (form) {
		new OnChangeForm(form, form.querySelector("[data-ajax-target]"));
	}
}
