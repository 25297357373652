class ProgressRing extends HTMLElement {
	constructor() {
		super();
		const stroke = 8;
		this._radius = 50;
		const percent = parseInt(this.getAttribute("progress"));
		const outerRadius = this._radius + stroke;
		this._outer_circumference = outerRadius * 2 * Math.PI;
		const color = this.getAttribute("color");
		const hidePercent = this.getAttribute("hide-percent") || false;
		const displayPercent = hidePercent
			? ""
			: this.getAttribute("progress")?.concat("", "%");
		const normalizedRadius = this._radius - stroke;
		this._circumference = normalizedRadius * 2 * Math.PI;
		this._root = this.attachShadow({ mode: "open" });
		const innerCircle =
			percent < 100
				? `
		<circle
			stroke="#edeff2"
			stroke-dasharray="${this._circumference} ${this._circumference}"
			style="stroke-dashoffset:${this._circumference + this._circumference}"
			stroke-width="${stroke}"
			fill="transparent"
			r="${normalizedRadius}"
			cx="${outerRadius}"
			cy="${outerRadius}"
			opacity="1"
	 	/>
		`
				: "";
		const outerCircle =
			percent > 100
				? `
		<circle
			stroke="${color}"
			stroke-dasharray="${this._outer_circumference} ${this._outer_circumference}"
			style="stroke-dashoffset:${this._outer_circumference}"
			stroke-width="${stroke + 1}"
			fill="transparent"
			r="${this._radius}"
			cx="${outerRadius}"
			cy="${outerRadius}"
			opacity="0.5"
	 	/>
		`
				: "";
		this._root.innerHTML = `
		<svg viewBox="0 0 ${outerRadius * 2} ${outerRadius * 2}">
		${innerCircle}
		<circle
			stroke="${color}"
			stroke-dasharray="${this._circumference} ${this._circumference}"
			style="stroke-dashoffset:${this._circumference + this._circumference}"
			stroke-width="${stroke}"
			fill="transparent"
			r="${normalizedRadius}"
			cx="${outerRadius}"
			cy="${outerRadius}"
			opacity="1"
		/>
		${outerCircle}
		  <text x="50%" y="53%" dominant-baseline="middle" text-anchor="middle">${displayPercent}</text>
		</svg>
		<style>
			svg {
				width: 100%;
				height: auto;
				display: block;
			}
			circle {
				transform: rotate(-90deg);
				transform-origin: 50% 50%;
			}
		</style>
		`;
		if (!outerCircle) {
			this.classList.add("no-outer-circle");
		}
	}

	setProgress(percent) {
		let circle = this._root.querySelectorAll("circle")[1];
		let offset;
		if (percent < 100) {
			offset = this._circumference - (percent / 100) * this._circumference;
		} else {
			let decimal = (percent - 100) / 100;
			offset =
				this._outer_circumference -
				(decimal * this._outer_circumference - decimal * this._radius);
		}
		if (circle) {
			circle.style.strokeDashoffset = offset;
		}
	}

	static get observedAttributes() {
		return ["progress"];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (name === "progress") {
			this.setProgress(newValue);
		}
	}
}

window.customElements.define("progress-ring", ProgressRing);

export default ProgressRing;
