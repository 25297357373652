export class JoinConfirmForm extends HTMLElement {
	constructor(object_id, abn, name) {
		super();
		this.object_id = object_id;
		this.abn = abn;
		this.name = name;
		this.classList.add(
			"flex",
			"px-5",
			"flex-col",
			"items-start",
			"gap-2",
			"sm:gap-0",
			"sm:items-center",
			"sm:justify-between",
			"sm:flex-row"
		);
	}

	connectedCallback() {
		this.innerHTML = `
            <input name='object_id' type='hidden' value="${this.object_id}" />
            <div><strong>ABN:</strong>
            <input name='abn' type='text' value="${this.abn}" /></div>
            <div><strong>Name:</strong>
            <input name='name' type='text' value="${this.name}" /></div>
            <button type='submit' class='app-page-link'>Confirm</button>
        `;
	}

	static get observedAttributes() {
		return ["object_id", "abn", "name"];
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (oldValue != newValue) {
			this.querySelector(`[name="${name}"]`).value = newValue;
		}
	}
}

customElements.define("confirm-form", JoinConfirmForm);
