const focusTrap = () => {
	const lastMenuLink = document.querySelector(
		"[data-navigation-target] .dropdown:last-child a:last-child"
	);

	if (lastMenuLink) {
		lastMenuLink.addEventListener("blur", (e) => {
			document.querySelector("[data-navigation-trigger]").focus();
		});
	}
};

export default focusTrap;
