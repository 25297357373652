class Menu extends HTMLElement {
	constructor() {
		super();
		this.menuHeader = this.querySelector(".menu-header");
		this.menuButton = this.querySelector(".menu-btn");
		this.halfLogo = this.querySelector(".half-logo");
		this.fullLogo = this.querySelector(".full-logo");
		this.text = this.querySelectorAll(".text");
		this.menu = this.querySelector("aside");
		this.main = document.querySelector("main");
		this.main.style = "";

		this.menu.classList.add("app-menu");
		sessionStorage.getItem("open") === "false" || window.innerWidth < 1024
			? this.closeMenu()
			: this.openMenu();

		setTimeout(() => {
			this.menu.classList.add("duration-500");
		}, 500);

		let resizeTimer;
		window.addEventListener("resize", (_) => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout((_) => {
				if (document.documentElement.clientWidth < 768) {
					this.closeMenu();
				}
			});
		});
	}

	connectedCallback() {
		this.menuButton.addEventListener("click", () => {
			sessionStorage.getItem("open") === "false"
				? this.openMenu()
				: this.closeMenu();
		});
	}

	closeMenu() {
		this.menu.classList.remove("w-[250px]", "is-open");
		this.menuHeader.classList.add("flex-col");
		this.menuButton.classList.add("mb-4", "rotate-180");
		this.halfLogo.classList.add("hidden");
		this.fullLogo.classList.remove("hidden");
		this.text.forEach((elem) => elem.classList.add("hidden"));
		this.main.style = "max-width: calc(100vw - 70px)";
		sessionStorage.setItem("open", "false");
	}

	openMenu() {
		this.menu.classList.add("w-[250px]", "is-open");
		this.menuHeader.classList.remove("flex-col");
		this.menuButton.classList.remove("mb-4", "rotate-180");
		this.halfLogo.classList.remove("hidden");
		this.fullLogo.classList.add("hidden");
		this.text.forEach((elem) => elem.classList.remove("hidden"));
		if (window.innerWidth > 1024) {
			this.main.style = "max-width: calc(100vw - 250px)";
		} else {
			this.main.style = "max-width: calc(100vw - 70px)";
		}
		sessionStorage.setItem("open", "true");
	}
}

customElements.define("app-menu", Menu);
