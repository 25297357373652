
// .build must be called at the end of method chaining to return the element itself.

export class ElementBuilder {
	constructor(tagName) {
		this.element = document.createElement(tagName);
	}

	classes(...classes) {
		this.element.classList.add(...classes);
		return this;
	}

	id(id) {
		this.element.id = (id);
		return this;
	}

	type(type) {
		this.element.type = type;
		return this;
	}

	name(name) {
		this.element.name = name;
		return this;
	}

	value(value) {
		this.element.value = value;
		return this;
	}

	placeholder(placeholder) {
		this.element.placeholder = placeholder;
		return this;
	}

	tabIndex(tabIndex) {
		this.element.tabIndex = tabIndex;
		return this;
	}

	addEventListener(event, callback) {
		this.element.addEventListener(event, callback);
		return this;
	}

	setAttribute(attribute, value) {
		this.element.setAttribute(attribute, value);
		return this;
	}

	innerHtml(innerHtml) {
		this.element.innerHTML = innerHtml;
		return this;
	}

	innerText(innerText) {
		this.element.innerText = innerText;
		return this;
	}

	// the parent method cannot be called by ElementBuilder inside a child method
	parent(parent) {
		parent.append(this.element);
		return this;
	}

	parentFirst(parent) {
		parent.insertAdjacentElement('afterbegin', this.element);
		return this;
	}

	beforeBegin(sibling) {
		sibling.insertAdjacentElement('beforebegin', this.element);
		return this;
	}

	child(child) {
		this.element.append(child);
		return this;
	}

	height(height) {
		this.element.style.height = height;
		return this;
	}

	width(width) {
		this.element.style.width = width;
		return this;
	}

	top(top) {
		this.element.style.top = top;
		return this;
	}

	get build() {
		return this.element;
	}
}
