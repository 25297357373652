import Alpine from 'alpinejs';
import FormSet from "../components/FormSet";

// We put a python booleans straight into the template
window.True = true;
window.False = false;


Alpine.data('unload', () => ({
    init() {
        window.addEventListener("beforeunload", (event) => {
            const target = event.target.activeElement;
            if (target.tagName === "BUTTON" || target.classList.contains("no-unload")) {
                // Don't prevent form submissions
                return;
            }
            event.preventDefault();
            // Legacy
            event.returnValue = true;
            return true;
        });
    }
}))

Alpine.data('abnLookup', (abnLookupUrl = '') => ({
    url: abnLookupUrl,
    init() {
        this.initEventListeners();
    },
    initEventListeners() {
        this.$el.addEventListener('change', (e) => {
            this.lookupAbn(e.target.value);
        })
    },
    lookupAbn(abn) {
        fetch(`${this.url}?abn=${abn}`).then(response => response.json()).then(data => {
            if (Object.keys(data).length === 0) {
                return;
            } else {
                for (const [key, value] of Object.entries(data)) {
                    const input = document.querySelector(`[name="${key}"]`);
                    const event = new Event("change");
                    if (input) {
                        input.value = value;
                        input.dispatchEvent(event);
                    }
                }
            }
        })
    }

}))

Alpine.data('formset', FormSet)


window.Alpine = Alpine
Alpine.start()
