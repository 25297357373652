class SelectAllToggle extends HTMLElement {
	connectedCallback() {
		const selectAll = this.querySelector("[data-toggle-all]");
		const name = selectAll.getAttribute("data-toggle-all");
		const otherToggles = document.querySelectorAll(`[name=${name}]`);
		selectAll.addEventListener("change", (e) => {
			e.preventDefault();
			otherToggles.forEach((t) => (t.checked = selectAll.checked));
		});

		otherToggles.forEach((t) => {
			t.addEventListener("change", () => {
				const allChecked = Array.from(otherToggles).every((t) => t.checked);
				selectAll.checked = allChecked;
			});
		});
	}
}

customElements.define("select-all-toggle", SelectAllToggle);
