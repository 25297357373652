const dropdownNavHover = () => {
	const dropdowns = document.querySelectorAll("[data-nav-dropdown]");

	dropdowns.forEach((dropdown) => {
		dropdown.addEventListener("mouseenter", () => {
			if (
				document.activeElement &&
				document.activeElement.hasAttribute("data-nav-dropdown")
			)
				document.activeElement.blur();
		});
	});
};

export default dropdownNavHover;
