export const rollingNumber = _ => {
	const containers  = document.querySelectorAll('.rolling-number-container');
	if (!containers) return;

	const rollingObserver = new IntersectionObserver((entries, observer) => {
		entries.forEach(entry => {
			if(entry.isIntersecting){
				entry.target.classList.add('rolling-number-animation');
				observer.unobserve(entry.target);
			}
		});
	}, {threshold: 0.9});

	containers.forEach(c => {
		rollingObserver.observe(c);
		c.querySelectorAll('.rolling-number').forEach(r => {
			let spans = r.querySelectorAll('span');
			r.style.width = `${spans[spans.length-1].offsetWidth}px`; // set width to final number rather than taking largest width
		})
	});
}
