export class Tabs {
	constructor(tabsEl) {
		this.tabsEl = tabsEl;
		tabsEl
			.querySelector("#slide-1")
			.classList.replace("opacity-0", "slide-active");
		tabsEl.querySelector("#slide-1").classList.remove("pointer-events-none");
		tabsEl.querySelector("#tab-1").classList.add("tab-active");
		tabsEl.querySelectorAll(".tab-btn").forEach((btn) => {
			btn.addEventListener("click", (e) => {
				this.showSlides(btn.id);
				this.containerResize();
			});
		});
		this.showSlides("tab-1");
		this.mobileDropdown();
		this.updateTabIndex();
		let resizeTimer;
		window.addEventListener("resize", () => {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(() => {
				this.containerResize();
			}, 250);
		});
		setTimeout(() => {
			this.containerResize();
		}, 250);
	}

	containerResize() {
		const slidesHeight =
			this.tabsEl.querySelector(".slide-active").offsetHeight;
		this.tabsEl.querySelector(".slide-container").style.height = `${
			slidesHeight + 4
		}px`;
	}

	showSlides(tabId) {
		this.tabsEl.querySelector(".tab-active").classList.toggle("tab-active");
		this.tabsEl.querySelector(`#${tabId}`).classList.toggle("tab-active");
		this.tabsEl
			.querySelector(".slide-active")
			.classList.add("pointer-events-none");
		this.tabsEl
			.querySelector(".slide-active")
			.classList.replace("slide-active", "opacity-0");
		const slidesId = `slide-${tabId.substring(4)}`;
		this.tabsEl
			.querySelector(`#${slidesId}`)
			.classList.replace("opacity-0", "slide-active");
		this.tabsEl
			.querySelector(`#${slidesId}`)
			.classList.remove("pointer-events-none");
		this.updateTabIndex();
	}

	mobileDropdown() {
		const dropdown = this.tabsEl.querySelector("#role-dropdown") || null;
		if (!dropdown) return;
		const select = dropdown.querySelector("select");
		select.onchange = () => {
			this.showSlides(dropdown.select.value);
			this.containerResize();
		};
	}

	updateTabIndex() {
		this.tabsEl
			.querySelectorAll(".slide-container a, .slide-container button")
			.forEach((el) => {
				el.tabIndex = "-1";
			});
		this.tabsEl
			.querySelectorAll(".slide-active a, .slide-active button")
			.forEach((el) => {
				el.tabIndex = "0";
			});
	}
}
