// Web components (self initialise)
import "./components/AppMenu";
import "./components/dropdown";
import "./components/FormSet";
import "./components/SelectAllToggle";
import "./components/TextCarousel";
import "./includes/ProgressRing";

import setupModals from "./components/AppModal";
import { rollingNumber } from "./components/rollingNumber";
// Interactive classes
import { Carousel } from "./includes/carousel";
import dropdownNavHover from "./includes/dropdownNavHover";
import focusTrap from "./includes/focusTrap";
import { dynamicValue } from "./includes/inputDynamicValue";
import joinConfirm from "./includes/joinConfirm";
import menuActions from "./includes/menuActions";
import onChangeForm from "./includes/onChangeForm";
import searchForm from "./includes/searchForm";
import { Tabs } from "./includes/tabs";
import togglePasswordVisibility from "./includes/togglePasswordVisibility";

// Third party
import "./includes/alpine";
import "./includes/htmx";

if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		navigator.serviceWorker
			.register("/sw.js")
			.then((registration) => {
				console.log("SW registration succeeded:", registration);
				navigator.serviceWorker.ready.then(function (registration) {
					console.log("SW is active:", registration.active);
				});
			})
			.catch((registrationError) => {
				console.log("SW registration failed: ", registrationError);
			});
	});
}

document.addEventListener("DOMContentLoaded", function () {
	// new OutdatedBrowserNotice(false, browserOutdated => {
	// 	if (browserOutdated) {
	// 		document.body.classList.add("browser-is-outdated");
	// 	}
	// });

	joinConfirm();
	searchForm();
	setupModals();
	rollingNumber();
	focusTrap();
	dropdownNavHover();
	togglePasswordVisibility();
	menuActions();
	document.querySelectorAll("[data-carousel]").forEach((c) => new Carousel(c));
	document.querySelectorAll("[data-tabs]").forEach((t) => new Tabs(t));
	document.querySelectorAll("form").forEach((f) => dynamicValue(f));
	document
		.querySelectorAll("[data-filter-container]")
		.forEach((f) => filterContainer(f));

	onChangeForm();

	document.body.classList.remove("preload");

	let loginForm = document.querySelector("[data-login]");
	if (loginForm) {
		loginForm.addEventListener("submit", () => {
			document.querySelector("#curves").classList.add("animate-spin");
		});
	}

	document.querySelectorAll("form").forEach((form) => {
		const submitButton = form.querySelector('button[type="submit"]');
		if (submitButton) {
			form.addEventListener("submit", (e) => {
				// submitButton.disabled = true; // doesn't work with unload
				submitButton.classList.add("btn-disabled", "pointer-events-none");
			});
		}
	});
});
